import './theme/index.css';

import Highlight, { defaultProps, Language } from 'prism-react-renderer';
import * as React from 'react';
import useClipboard from 'react-use-clipboard';

import styles from './styles.module.css';

type CodeProps = {
  codeString: string;
  language?: Language;
};

const Code: React.FC<CodeProps> = ({
  codeString,
  language = 'text',
  ...props
}) => {
  const [isCopied, setCopied] = useClipboard(codeString, {
    successDuration: 2000,
  });

  return (
    <Highlight
      {...defaultProps}
      {...props}
      code={codeString}
      language={language as Language}
      theme={void 0}
    >
      {({
        className,
        style,
        tokens,
        getLineProps,
        getTokenProps,
      }): React.ReactElement => {
        return (
          <div className="gatsby-highlight" data-language={language}>
            <div className={styles.LanguageBadgeWrap}>
              <span className={styles.LanguageBadge} data-language={language}>
                {language.toUpperCase()}
              </span>
              <button className={styles.CopyButton} onClick={setCopied}>
                {isCopied ? 'Copied!' : 'Copy'}
              </button>
            </div>
            <pre className={className} style={style}>
              {tokens.map((line, i) => {
                const lineProps = getLineProps({ line, key: i });

                return (
                  <div key={i} {...lineProps}>
                    {line.map((token, key) => (
                      <span key={key} {...getTokenProps({ token, key })} />
                    ))}
                  </div>
                );
              })}
            </pre>
          </div>
        );
      }}
    </Highlight>
  );
};

export default Code;
