import Prism from 'prism-react-renderer/prism';

import { wrapRootElement as wrap } from './src/components/Code/wrap-root-element';
(typeof globalThis !== 'undefined' ? globalThis : window).Prism = Prism;

export const wrapRootElement = wrap;

// Using line numbering for code blocks
require('prismjs/plugins/line-numbers/prism-line-numbers');

// Using shell prompt in code blocks
require('prismjs/plugins/command-line/prism-command-line.css');

// You can import missing languages supported by prismjs – https://prismjs.com/
require('prismjs/components/prism-jsx');
require('prismjs/components/prism-tsx');
require('prismjs/components/prism-typescript');
require('prismjs/components/prism-javascript');
require('prismjs/components/prism-yaml');
require('prismjs/components/prism-json');
