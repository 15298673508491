import { MDXProvider, MDXProviderComponentsProp } from '@mdx-js/react';
import { preToCodeBlock } from 'mdx-utils';
import * as React from 'react';

import Code from './index';

const components: MDXProviderComponentsProp = {
  pre: preProps => {
    const props = preToCodeBlock(preProps);
    // if there's a codeString and some props, we passed the test
    if (props) {
      return <Code {...props} />;
    }

    // it's possible to have a pre without a code in it
    return <pre {...preProps} />;
  },
  wrapper: ({ children }) => <>{children}</>,
};

export const wrapRootElement: React.FC<any> = ({ element }) => (
  <MDXProvider components={components}>{element}</MDXProvider>
);
